import React from "react"
import Layout from "hoc/Layout"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import Font24 from "components/Fonts/Font24"
import Font16 from "components/Fonts/Font16"

const RulesPage = () => {
  const breadcrumbsData = [
    {
      title: "Startseite",
      link: "/",
    },
    "Vorschriften und Einwilligungen",
  ]

  return (
    <Layout
      breadcrumbsData={breadcrumbsData}
      seoTitle="Vorschriften und Einwilligungen"
    >
      <Hr mb={4} mt={6} />
      <Font42 mb={4}>Vorschriften und Einwilligungen</Font42>
      <Font16 textAlign="justify" mb={3}>
        Im Zusammenhang mit dem Schutz personenbezogener Daten, damit wir Ihnen
        Stellenangebote präsentieren können, lesen Sie bitte die folgenden
        Einwilligungen zur Verarbeitung personenbezogener Daten bei dem
        Rektutierungsprozess.
      </Font16>
      <Font16 textAlign="justify" mb={3}>
        Die folgenden Zustimmungen gelten für den Datenverwalter Medira Pflege
        Sp. z o.o. Sp. K. mit Sitz in Katowice, Ul. Mielęckiego 10.
      </Font16>
      <Font24 mb={3}>Zustimmung</Font24>
      <Font16 textAlign="justify" mb={3}>
        Ich stimme der Verarbeitung meiner personenbezogenen Daten durch Medira
        Pflege Sp. z o.o. Sp. K. mit Sitz in Katowice, Ul. Mielęckiego 10, für
        die Bedürfnisse dieser und zukünftiger Rekrutierung.
      </Font16>
      <Font16 textAlign="justify" mb={3}>
        Ich bin damit einverstanden, eingehende Telefonanrufe von Medira Pflege
        Sp. z o.o. Sp. K. mit Sitz in Katowice, Ul. Mielęckiego 10 für Handels-
        und Marketingzwecke zu erhalten.
      </Font16>
      <Font16 textAlign="justify" mb={3}>
        Ich stimme zu, von Medira Pflege Sp z o.o. Sp. K. mit Sitz in Katowice,
        Ul. Mielęckiego 10, Handelsinformationen über elektronische
        Kommunikationsmittelzu bekommen.
      </Font16>
      <Font16 textAlign="justify" mb={3}>
        Ich stimme der Verarbeitung der oben genannten personenbezogenen Daten
        durch Medira Pflege Sp. z o.o. Sp. K. mit Sitz in Katowice, Ul.
        Mielęckiego 10, zum Aufbau von Kunden- und Marketingbeziehungen sowie
        zum Anbieten eigener Produkte und Dienstleistungen.
      </Font16>
      <Font24 mb={3}>Informationsklausel</Font24>
      <Font16 textAlign="justify" mb={3}>
        Der Datenverwalter Ihrer persönlichen Daten ist Medira Pflege Sp. z o.o.
        Sp. K. mit Sitz in Katowice, Ul. Mielęckiego 10. Personenbezogene Daten
        werden gemäß den Zwecken verarbeitet und aufbewahrt, bis der Zweck endet
        oder die Zustimmung widerrufen wird. Sie haben jederzeit das Recht auf
        Auskunft über den Inhalt Ihrer Daten sowie das Recht auf Berichtigung,
        Löschung, Einschränkung der Verarbeitung, das Recht auf
        Datenübertragbarkeit, Widerspruch und Widerruf. Sie haben das Recht,
        eine Beschwerde bei der Aufsichtsbehörde einzureichen, wenn Sie der
        Meinung sind, dass die Verarbeitung Ihrer personenbezogenen Daten gegen
        die.
      </Font16>
    </Layout>
  )
}

export default RulesPage
